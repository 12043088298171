import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledDiv = styled.div`
    position:absolute;
    left: 50%;
    transform:translate(-50%, 25%);
    text-align: center;
    max-height: 50vh;

    & > *{
        max-height: 100%;
    }
`

const StyledSvg = styled.svg`
    .cls-1{fill:#8dacc7;}
    .cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6,.cls-7{stroke:#fff;stroke-linejoin:round;stroke-width:0.35px;}
    .cls-2{fill:#9ab4cd;}
    .cls-3{fill:#b5c6d9;}
    .cls-4{fill:#7ea3c1;}
    .cls-5{fill:#c2cfe0;}
    .cls-6{fill:#ced8e6;}
    .cls-7{fill:#a8bdd3;}
    max-height: 400px;
`

const NoScript = () => (
    <StyledDiv>
      <div>
        <StyledSvg 
            id="Ebene_1" 
            data-name="Ebene 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 257.23 294.34">
            <title>Gestalt Logo</title>
            <polygon className="cls-1" points="122.68 223.72 122 173.08 177.69 171.3 122.68 223.72"/>
            <polygon className="cls-2" points="96.48 238.5 122.68 223.72 122 173.08 96.48 238.5"/>
            <polygon className="cls-3" points="58.55 294.07 122.68 223.72 96.48 238.5 58.55 294.07"/>
            <polygon className="cls-4" points="163.02 114.26 177.69 171.3 122 173.08 163.02 114.26"/>
            <polygon className="cls-2" points="174.76 118.39 163.02 114.26 177.69 171.3 174.76 118.39"/>
            <polygon className="cls-5" points="222.26 120.73 174.76 118.39 177.69 171.3 222.26 120.73"/>
            <polygon className="cls-3" points="208.55 145.89 222.26 120.73 177.69 171.3 208.55 145.89"/>
            <polygon className="cls-2" points="244.98 148.22 208.55 145.89 222.26 120.73 244.98 148.22"/>
            <polygon className="cls-3" points="222.26 120.73 238.54 129.79 244.98 148.22 222.26 120.73"/>
            <polygon className="cls-2" points="238.54 129.79 256.95 161.1 244.98 148.22 238.54 129.79"/>
            <polygon className="cls-6" points="233.62 147.51 256.95 161.1 244.98 148.22 233.62 147.51"/>
            <polygon className="cls-6" points="48.46 68.65 64.66 93.1 131.31 61.86 48.46 68.65"/>
            <polygon className="cls-3" points="64.66 93.1 81.43 118.41 131.31 61.86 64.66 93.1"/>
            <polygon className="cls-2" points="0.28 0.28 66.28 16.12 27.16 36.5 0.28 0.28"/>
            <polygon className="cls-7" points="131.31 61.86 66.28 16.12 27.28 36.67 131.31 61.86"/>
            <polygon className="cls-1" points="76.91 48.62 27.28 36.67 48.46 68.65 131.31 61.86 76.91 48.62"/>
            <polygon className="cls-1" points="163.02 114.26 131.31 61.86 122 173.08 163.02 114.26"/>
            <polygon className="cls-2" points="122 173.08 81.43 118.41 131.31 61.86 122 173.08"/>
        </StyledSvg>
      </div>
      <div>
          <p><Link to="/">Aquaplot</Link> by <Link to="/company">Gestalt Systems</Link></p>
      </div>
      <p>Please activate JavaScript and reload this page.</p> 
    </StyledDiv>
)

export default NoScript